@import url('https://fonts.googleapis.com/css?family=Advent+Pro:100|Fredericka+the+Great|Orbitron:700|Signika:700|Source+Sans+Pro');


html, body {
    position: relative;
    height: 100%;
}
body {
    background: #FEFCF5;
    background: white;
    color: #1E0C05;
    color: black;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2em;

    padding: 0 13ex;
}

h1 {
    font-size: 3em;
    font-family: 'Fredericka the Great', cursive;
    font-family: 'Advent Pro', sans-serif;
}
h2 {
    font-size: 2em;
    font-family: 'Signika', sans-serif;
    text-align: right;
    border-bottom: 1px solid;
    padding: 0 1ex;
    margin: 4rem 0 1rem;
}
h3 {
    font-size: 1.5em;
    font-family: 'Orbitron', sans-serif;
    margin: 0;
}
h4 {
    font-family: 'Advent Pro', sans-serif;
    padding: 0 1em;

    &::before {
        content: "~ ";
    }
}

a {
    &:link, &:visited {
        color: inherit;
        text-decoration: underline;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    &.fa {
        text-decoration: none;

        &:hover {
            background: transparent;
            text-decoration: underline;
        }
    }
}

header.page {
    position: relative;
    height: 90%;
    margin: 0 -13ex;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* don't like this - but without, the content wraps on mobile devices */
    background: url('/img/background.jpg') center bottom fixed;
    background-size: cover;

    color: black;
    text-shadow: 0 1px white;

    /* Transparent background */
    &>.click {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;

        &::after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
    }
    &>.logo {
        display: none;
        position: absolute;
        top: 1em;
        right: 1em;
        width: 8em;
        opacity: 0.3;
    }

    /* Vertically center content */
    &::after, &>div.content {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }
    &>div.content {
        width: 100%;
    }
    &>div.top {
        position: absolute;
        top: 0;
        width: 100%;
    }
    &>div.bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    
    /* Text styles */
    h1, h2, h3, h4 {
        text-align: center;
    }

    h1 {
        font-size: 8rem;
    }
    h2 {
        font-size: 3rem;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1rem;

        &::before {
            content: none;
        }
    }
}

footer.site {
    display: flex;
    margin: 4rem -13ex 0;
    padding: 1em 13ex;

    background: #EEE;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &>* {
        flex-grow: 1;
        font-size: 0.8rem;
    }
}

ul.gallery {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1em;
    list-style: none;
    flex-wrap: wrap;

    li {
        height: 200px;
        border-bottom: 1px solid;
        padding: 1px 0 1px 1ex;

        img {
            height: inherit;
            border-radius: 4px;
        }
        &::after {
            display: inline-block;
            content: "";
            height: 2em;
            width: 1ex;
            border-right: 1px solid;
            vertical-align: middle;
        }
    }
}
